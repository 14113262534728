import {Api} from "nocodb-sdk";

export async function getRandomQuote() {
  const api = new Api({
    baseURL: "https://factory.geek.mg/",
    headers: {
      "xc-token": "RTZSe52AqlGdCkhh0c9hQGo8DvlDOK0-KsDhqAEi"
    }
  })

  const count = await api.dbViewRow.count("v1", "p51nhdb83fd6qb2", "ohabolana", "Classeur1Csv");
  const random_id = Math.floor(Math.random() * count["count"]) + 1
  const q = await api.dbTableRow.read("v1", "p51nhdb83fd6qb2", "ohabolana", random_id.toString());
  return q;
}
